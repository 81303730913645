import React from 'react';
import GetStartedTodayButton from '../get-started-today-button';

export default props => {
  return (
    <div className='plan-types-content-outer-container medicare-page'>
      <div className={`plan-types-individual-container`}>
        <div className={`plan-types-overview`}>MEDICARE SUPPLEMENT INSURANCE OVERVIEW</div>
        <div className={`plan-types-title`}>MEDICARE SUPPLEMENT INSURANCE</div>

        <div className={`plan-types-text`}>
          Medicare comes in two parts; Part A: Hospital Insurance and Part B: Medical Insurance. However, these policies leave gaps in your coverage and you may
          need to think about a supplemental plan. Medicare Supplement Plans are also called Medigap Plans. These types of policies can help with the cost of
          your deductibles, copayments, coinsurance and excess charges depending on the coverage you choose.
          <br />
          <br />
          Want to fill in your medicare gaps and get the coverage you really need at an overall better cost? Call us today and let an Apollo agent help you.
        </div>
        <div className={`plan-types-header`}>Who Qualifies for Medicare?</div>
        <div className={`plan-types-text`}>
          Medicare is for individuals who are american citizens over the age of 65. A person must have worked a minimum of 40 quarters in their life to qualify
          or had a spouse that did so.Those who are permanently disabled, received SSI for a minimum of 2 consecutive years, been diagnosed with Lou Gehrig’s
          disease or permanent kidney failure also qualify for Medicare. Medicare is broken down into 4 parts; Part A: Hospital Coverage, Part B:
          Doctor/Outpatient Services, Part C: Medicare Advantage, Part D: Prescription Drugs.
        </div>
        <div className={`plan-types-header`}>The Different Medigap Plan Options</div>
        <div className={`plan-types-text`}>
          There are 10 different types of Medigap plans available to Medicare recipients. Each medigap plan has its own perks and is suited for its own thing.
          Plan F is the most popular and covers the most. This plan will pay for the items that you would normally have to cover. Medigap Plan D is the least
          popular as it doesn’t cover your Part B deductible and Medicare excess charges. With so many different options it can get confusing, talk to an Apollo
          agent today.{' '}
        </div>
        <div className={`plan-types-header`}>Open Enrollment</div>
        <div className={`plan-types-text`}>
          Medigap plans can be purchased from October 15th to December 7th of every year. At this time, you can enroll, dis-enroll or change your Part D or
          Medicare Advantage plan. However, when you first turn 65 and have enrolled in Part B of Medicare, you get a 6 month enrollment period starting from
          the Part B effective date. Those who receive Medicare at an earlier age than 65, will have a second open enrollment period when they turn 65.
        </div>
        <div className={`plan-types-header`}>Medigap Update</div>
        <div className={`plan-types-text`}>
          January 1, 2020 started a change in Medigap eligibility for Plans C and F. These two plans will no longer be available to newly eligible Medicare
          beneficiaries to purchase but can be kept if you're already enrolled. The plans are for Medigap Plans D and G to take their place. Medicare is
          constantly changing and it’s important for you to stay up to date, call us today.
        </div>
      </div>

      <div className='plan-types-medicare-divider'>
        <span>Keeping your costs minimal and your benefits optimal</span>
      </div>

      <div className='plan-types-individual-container'>
        <div className={`plan-types-header`}>
          Full Coverage When You Need It. <br />
          No Surprises
        </div>
        <div className={`plan-types-text`}>
          If you are an American Citizen and you have turned 65 or older, then you have the option to enroll in Medicare. Medicare Parts A and B, cover your
          basic hospital and doctor expenses. Unfortunately these two plans do not cover all of your basic hospital and doctor expenses. Medicare supplemental
          insurance can protect you from expenses that are not covered. We are very well versed in this area as well and we can help our clients understand what
          basic medicare does and does not cover.
        </div>

        <div className={`plan-types-title plan-types-title-subtitle font-blue`}>OUR GOAL</div>
        <div className={`plan-types-text`}>
          We help make sure your family is taken care of if anything were to happen to you. We offer products to our clients which will help provide for them
          and their families in the event of a long life, disability, or premature death. Our team takes the time to educate our clients and help them
          understand why they need life insurance, how life insurance works, and how to customize your coverage to meet your needs.
        </div>

        <div className={`plan-types-title plan-types-title-subtitle font-blue`}>HOW WE HELP</div>
        <div className={`plan-types-text`}>
          At Apollo, we understand what family means to you, and we do everything possible to help you plan for the best and worst case scenarios of your
          future. We want to get to know you, your story, and your family. Allow us to work alongside you and help make your dreams become a reality. You dream
          it, we plan it, and together we will achieve it!
        </div>
      </div>
      <GetStartedTodayButton {...props} />
    </div>
  );
};
