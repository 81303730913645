import React from 'react';

const style = {
  cursor: 'pointer',
  border: '3px solid #0D74BA',
  width: '228px',
  borderRadius: '10px',
  height: '48px',
  margin: '0px auto',
  color: '#0D74BA',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Montserrat',
  fontSize: '14px',
  fontWeight: 700,
  marginTop: '36px'
};

export default props => {
  return (
    <div
      style={style}
      onClick={() => {
        props.setGetAQuoteOpened && props.setGetAQuoteOpened(!props.getAQuoteOpened);
      }}
      className='get-started-today-button'>
      GET STARTED TODAY
    </div>
  );
};
